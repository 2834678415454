<template>
  <vs-row vs-justify="center">
    <vs-col
      type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="12"
      vs-xs="12"
    >
      <vs-card>
        <h3 class="card-title d-flex">
          Arrows & Direction Icons
        </h3>
        <div class="icon-list-demo row">
          <div class="m-icon">
            <i class="ti-arrow-up" /> ti-arrow-up
          </div>
          <div class="m-icon">
            <i class="ti-arrow-right" /> ti-arrow-right
          </div>
          <div class="m-icon">
            <i class="ti-arrow-left" /> ti-arrow-left
          </div>
          <div class="m-icon">
            <i class="ti-arrow-down" /> ti-arrow-down
          </div>
          <div class="m-icon">
            <i class="ti-arrows-vertical" /> ti-arrows-vertical
          </div>
          <div class="m-icon">
            <i class="ti-arrows-horizontal" /> ti-arrows-horizontal
          </div>
          <div class="m-icon">
            <i class="ti-angle-up" /> ti-angle-up
          </div>
          <div class="m-icon">
            <i class="ti-angle-right" /> ti-angle-right
          </div>
          <div class="m-icon">
            <i class="ti-angle-left" /> ti-angle-left
          </div>
          <div class="m-icon">
            <i class="ti-angle-down" /> ti-angle-down
          </div>
          <div class="m-icon">
            <i class="ti-angle-double-up" /> ti-angle-double-up
          </div>
          <div class="m-icon">
            <i class="ti-angle-double-right" /> ti-angle-double-right
          </div>
          <div class="m-icon">
            <i class="ti-angle-double-left" /> ti-angle-double-left
          </div>
          <div class="m-icon">
            <i class="ti-angle-double-down" /> ti-angle-double-down
          </div>
          <div class="m-icon">
            <i class="ti-move" /> ti-move
          </div>
          <div class="m-icon">
            <i class="ti-fullscreen" /> ti-fullscreen
          </div>
          <div class="m-icon">
            <i class="ti-arrow-top-right" /> ti-arrow-top-right
          </div>
          <div class="m-icon">
            <i class="ti-arrow-top-left" /> ti-arrow-top-left
          </div>
          <div class="m-icon">
            <i class="ti-arrow-circle-up" /> ti-arrow-circle-up
          </div>
          <div class="m-icon">
            <i class="ti-arrow-circle-right" /> ti-arrow-circle-right
          </div>
          <div class="m-icon">
            <i class="ti-arrow-circle-left" /> ti-arrow-circle-left
          </div>
          <div class="m-icon">
            <i class="ti-arrow-circle-down" /> ti-arrow-circle-down
          </div>
          <div class="m-icon">
            <i class="ti-arrows-corner" /> ti-arrows-corner
          </div>
          <div class="m-icon">
            <i class="ti-split-v" /> ti-split-v
          </div>
          <div class="m-icon">
            <i class="ti-split-v-alt" /> ti-split-v-alt
          </div>
          <div class="m-icon">
            <i class="ti-split-h" /> ti-split-h
          </div>
          <div class="m-icon">
            <i class="ti-hand-point-up" /> ti-hand-point-up
          </div>
          <div class="m-icon">
            <i class="ti-hand-point-right" /> ti-hand-point-right
          </div>
          <div class="m-icon">
            <i class="ti-hand-point-left" /> ti-hand-point-left
          </div>
          <div class="m-icon">
            <i class="ti-hand-point-down" /> ti-hand-point-down
          </div>
          <div class="m-icon">
            <i class="ti-back-right" /> ti-back-right
          </div>
          <div class="m-icon">
            <i class="ti-back-left" /> ti-back-left
          </div>
          <div class="m-icon">
            <i class="ti-exchange-vertical" /> ti-exchange-vertical
          </div>
        </div>
      </vs-card>
      <vs-card>
        <h3 class="card-title d-flex">
          Web App Icons
        </h3>
        <div class="icon-list-demo row">
          <div class="m-icon">
            <i class="ti-wand" /> ti-wand
          </div>
          <div class="m-icon">
            <i class="ti-save" /> ti-save
          </div>
          <div class="m-icon">
            <i class="ti-save-alt" /> ti-save-alt
          </div>
          <div class="m-icon">
            <i class="ti-direction" /> ti-direction
          </div>
          <div class="m-icon">
            <i class="ti-direction-alt" /> ti-direction-alt
          </div>
          <div class="m-icon">
            <i class="ti-user" /> ti-user
          </div>
          <div class="m-icon">
            <i class="ti-link" /> ti-link
          </div>
          <div class="m-icon">
            <i class="ti-unlink" /> ti-unlink
          </div>
          <div class="m-icon">
            <i class="ti-trash" /> ti-trash
          </div>
          <div class="m-icon">
            <i class="ti-target" /> ti-target
          </div>
          <div class="m-icon">
            <i class="ti-tag" /> ti-tag
          </div>
          <div class="m-icon">
            <i class="ti-desktop" /> ti-desktop
          </div>
          <div class="m-icon">
            <i class="ti-tablet" /> ti-tablet
          </div>
          <div class="m-icon">
            <i class="ti-mobile" /> ti-mobile
          </div>
          <div class="m-icon">
            <i class="ti-email" /> ti-email
          </div>
          <div class="m-icon">
            <i class="ti-star" /> ti-star
          </div>
          <div class="m-icon">
            <i class="ti-spray" /> ti-spray
          </div>
          <div class="m-icon">
            <i class="ti-signal" /> ti-signal
          </div>
          <div class="m-icon">
            <i class="ti-shopping-cart" /> ti-shopping-cart
          </div>
          <div class="m-icon">
            <i class="ti-shopping-cart-full" /> ti-shopping-cart-full
          </div>
          <div class="m-icon">
            <i class="ti-settings" /> ti-settings
          </div>
          <div class="m-icon">
            <i class="ti-search" /> ti-search
          </div>
          <div class="m-icon">
            <i class="ti-zoom-in" /> ti-zoom-in
          </div>
          <div class="m-icon">
            <i class="ti-zoom-out" /> ti-zoom-out
          </div>
          <div class="m-icon">
            <i class="ti-cut" /> ti-cut
          </div>
          <div class="m-icon">
            <i class="ti-ruler" /> ti-ruler
          </div>
          <div class="m-icon">
            <i class="ti-ruler-alt-2" /> ti-ruler-alt-2
          </div>
          <div class="m-icon">
            <i class="ti-ruler-pencil" /> ti-ruler-pencil
          </div>
          <div class="m-icon">
            <i class="ti-ruler-alt" /> ti-ruler-alt
          </div>
          <div class="m-icon">
            <i class="ti-bookmark" /> ti-bookmark
          </div>
          <div class="m-icon">
            <i class="ti-bookmark-alt" /> ti-bookmark-alt
          </div>
          <div class="m-icon">
            <i class="ti-reload" /> ti-reload
          </div>
          <div class="m-icon">
            <i class="ti-plus" /> ti-plus
          </div>
          <div class="m-icon">
            <i class="ti-minus" /> ti-minus
          </div>
          <div class="m-icon">
            <i class="ti-close" /> ti-close
          </div>
          <div class="m-icon">
            <i class="ti-pin" /> ti-pin
          </div>
          <div class="m-icon">
            <i class="ti-pencil" /> ti-pencil
          </div>
          <div class="m-icon">
            <i class="ti-pencil-alt" /> ti-pencil-alt
          </div>
          <div class="m-icon">
            <i class="ti-paint-roller" /> ti-paint-roller
          </div>
          <div class="m-icon">
            <i class="ti-paint-bucket" /> ti-paint-bucket
          </div>
          <div class="m-icon">
            <i class="ti-na" /> ti-na
          </div>
          <div class="m-icon">
            <i class="ti-medall" /> ti-medall
          </div>
          <div class="m-icon">
            <i class="ti-medall-alt" /> ti-medall-alt
          </div>
          <div class="m-icon">
            <i class="ti-marker" /> ti-marker
          </div>
          <div class="m-icon">
            <i class="ti-marker-alt" /> ti-marker-alt
          </div>
          <div class="m-icon">
            <i class="ti-lock" /> ti-lock
          </div>
          <div class="m-icon">
            <i class="ti-unlock" /> ti-unlock
          </div>
          <div class="m-icon">
            <i class="ti-location-arrow" /> ti-location-arrow
          </div>
          <div class="m-icon">
            <i class="ti-layout" /> ti-layout
          </div>
          <div class="m-icon">
            <i class="ti-layers" /> ti-layers
          </div>
          <div class="m-icon">
            <i class="ti-layers-alt" /> ti-layers-alt
          </div>
          <div class="m-icon">
            <i class="ti-key" /> ti-key
          </div>
          <div class="m-icon">
            <i class="ti-image" /> ti-image
          </div>
          <div class="m-icon">
            <i class="ti-heart" /> ti-heart
          </div>
          <div class="m-icon">
            <i class="ti-heart-broken" /> ti-heart-broken
          </div>
          <div class="m-icon">
            <i class="ti-hand-stop" /> ti-hand-stop
          </div>
          <div class="m-icon">
            <i class="ti-hand-open" /> ti-hand-open
          </div>
          <div class="m-icon">
            <i class="ti-hand-drag" /> ti-hand-drag
          </div>
          <div class="m-icon">
            <i class="ti-flag" /> ti-flag
          </div>
          <div class="m-icon">
            <i class="ti-flag-alt" /> ti-flag-alt
          </div>
          <div class="m-icon">
            <i class="ti-flag-alt-2" /> ti-flag-alt-2
          </div>
          <div class="m-icon">
            <i class="ti-eye" /> ti-eye
          </div>
          <div class="m-icon">
            <i class="ti-import" /> ti-import
          </div>
          <div class="m-icon">
            <i class="ti-export" /> ti-export
          </div>
          <div class="m-icon">
            <i class="ti-cup" /> ti-cup
          </div>
          <div class="m-icon">
            <i class="ti-crown" /> ti-crown
          </div>
          <div class="m-icon">
            <i class="ti-comments" /> ti-comments
          </div>
          <div class="m-icon">
            <i class="ti-comment" /> ti-comment
          </div>
          <div class="m-icon">
            <i class="ti-comment-alt" /> ti-comment-alt
          </div>
          <div class="m-icon">
            <i class="ti-thought" /> ti-thought
          </div>
          <div class="m-icon">
            <i class="ti-clip" /> ti-clip
          </div>
          <div class="m-icon">
            <i class="ti-check" /> ti-check
          </div>
          <div class="m-icon">
            <i class="ti-check-box" /> ti-check-box
          </div>
          <div class="m-icon">
            <i class="ti-camera" /> ti-camera
          </div>
          <div class="m-icon">
            <i class="ti-announcement" /> ti-announcement
          </div>
          <div class="m-icon">
            <i class="ti-brush" /> ti-brush
          </div>
          <div class="m-icon">
            <i class="ti-brush-alt" /> ti-brush-alt
          </div>
          <div class="m-icon">
            <i class="ti-palette" /> ti-palette
          </div>
          <div class="m-icon">
            <i class="ti-briefcase" /> ti-briefcase
          </div>
          <div class="m-icon">
            <i class="ti-bolt" /> ti-bolt
          </div>
          <div class="m-icon">
            <i class="ti-bolt-alt" /> ti-bolt-alt
          </div>
          <div class="m-icon">
            <i class="ti-blackboard" /> ti-blackboard
          </div>
          <div class="m-icon">
            <i class="ti-bag" /> ti-bag
          </div>
          <div class="m-icon">
            <i class="ti-world" /> ti-world
          </div>
          <div class="m-icon">
            <i class="ti-wheelchair" /> ti-wheelchair
          </div>
          <div class="m-icon">
            <i class="ti-car" /> ti-car
          </div>
          <div class="m-icon">
            <i class="ti-truck" /> ti-truck
          </div>
          <div class="m-icon">
            <i class="ti-timer" /> ti-timer
          </div>
          <div class="m-icon">
            <i class="ti-ticket" /> ti-ticket
          </div>
          <div class="m-icon">
            <i class="ti-thumb-up" /> ti-thumb-up
          </div>
          <div class="m-icon">
            <i class="ti-thumb-down" /> ti-thumb-down
          </div>
          <div class="m-icon">
            <i class="ti-stats-up" /> ti-stats-up
          </div>
          <div class="m-icon">
            <i class="ti-stats-down" /> ti-stats-down
          </div>
          <div class="m-icon">
            <i class="ti-shine" /> ti-shine
          </div>
          <div class="m-icon">
            <i class="ti-shift-right" /> ti-shift-right
          </div>
          <div class="m-icon">
            <i class="ti-shift-left" /> ti-shift-left
          </div>
          <div class="m-icon">
            <i class="ti-shift-right-alt" /> ti-shift-right-alt
          </div>
          <div class="m-icon">
            <i class="ti-shift-left-alt" /> ti-shift-left-alt
          </div>
          <div class="m-icon">
            <i class="ti-shield" /> ti-shield
          </div>
          <div class="m-icon">
            <i class="ti-notepad" /> ti-notepad
          </div>
          <div class="m-icon">
            <i class="ti-server" /> ti-server
          </div>
          <div class="m-icon">
            <i class="ti-pulse" /> ti-pulse
          </div>
          <div class="m-icon">
            <i class="ti-printer" /> ti-printer
          </div>
          <div class="m-icon">
            <i class="ti-power-off" /> ti-power-off
          </div>
          <div class="m-icon">
            <i class="ti-plug" /> ti-plug
          </div>
          <div class="m-icon">
            <i class="ti-pie-chart" /> ti-pie-chart
          </div>
          <div class="m-icon">
            <i class="ti-panel" /> ti-panel
          </div>
          <div class="m-icon">
            <i class="ti-package" /> ti-package
          </div>
          <div class="m-icon">
            <i class="ti-music" /> ti-music
          </div>
          <div class="m-icon">
            <i class="ti-music-alt" /> ti-music-alt
          </div>
          <div class="m-icon">
            <i class="ti-mouse" /> ti-mouse
          </div>
          <div class="m-icon">
            <i class="ti-mouse-alt" /> ti-mouse-alt
          </div>
          <div class="m-icon">
            <i class="ti-money" /> ti-money
          </div>
          <div class="m-icon">
            <i class="ti-microphone" /> ti-microphone
          </div>
          <div class="m-icon">
            <i class="ti-menu" /> ti-menu
          </div>
          <div class="m-icon">
            <i class="ti-menu-alt" /> ti-menu-alt
          </div>
          <div class="m-icon">
            <i class="ti-map" /> ti-map
          </div>
          <div class="m-icon">
            <i class="ti-map-alt" /> ti-map-alt
          </div>
          <div class="m-icon">
            <i class="ti-location-pin" /> ti-location-pin
          </div>
          <div class="m-icon">
            <i class="ti-light-bulb" /> ti-light-bulb
          </div>
          <div class="m-icon">
            <i class="ti-info" /> ti-info
          </div>
          <div class="m-icon">
            <i class="ti-infinite" /> ti-infinite
          </div>
          <div class="m-icon">
            <i class="ti-id-badge" /> ti-id-badge
          </div>
          <div class="m-icon">
            <i class="ti-hummer" /> ti-hummer
          </div>
          <div class="m-icon">
            <i class="ti-home" /> ti-home
          </div>
          <div class="m-icon">
            <i class="ti-help" /> ti-help
          </div>
          <div class="m-icon">
            <i class="ti-headphone" /> ti-headphone
          </div>
          <div class="m-icon">
            <i class="ti-harddrives" /> ti-harddrives
          </div>
          <div class="m-icon">
            <i class="ti-harddrive" /> ti-harddrive
          </div>
          <div class="m-icon">
            <i class="ti-gift" /> ti-gift
          </div>
          <div class="m-icon">
            <i class="ti-game" /> ti-game
          </div>
          <div class="m-icon">
            <i class="ti-filter" /> ti-filter
          </div>
          <div class="m-icon">
            <i class="ti-files" /> ti-files
          </div>
          <div class="m-icon">
            <i class="ti-file" /> ti-file
          </div>
          <div class="m-icon">
            <i class="ti-zip" /> ti-zip
          </div>
          <div class="m-icon">
            <i class="ti-folder" /> ti-folder
          </div>
          <div class="m-icon">
            <i class="ti-envelope" /> ti-envelope
          </div>
          <div class="m-icon">
            <i class="ti-dashboard" /> ti-dashboard
          </div>
          <div class="m-icon">
            <i class="ti-cloud" /> ti-cloud
          </div>
          <div class="m-icon">
            <i class="ti-cloud-up" /> ti-cloud-up
          </div>
          <div class="m-icon">
            <i class="ti-cloud-down" /> ti-cloud-down
          </div>
          <div class="m-icon">
            <i class="ti-clipboard" /> ti-clipboard
          </div>
          <div class="m-icon">
            <i class="ti-calendar" /> ti-calendar
          </div>
          <div class="m-icon">
            <i class="ti-book" /> ti-book
          </div>
          <div class="m-icon">
            <i class="ti-bell" /> ti-bell
          </div>
          <div class="m-icon">
            <i class="ti-basketball" /> ti-basketball
          </div>
          <div class="m-icon">
            <i class="ti-bar-chart" /> ti-bar-chart
          </div>
          <div class="m-icon">
            <i class="ti-bar-chart-alt" /> ti-bar-chart-alt
          </div>
          <div class="m-icon">
            <i class="ti-archive" /> ti-archive
          </div>
          <div class="m-icon">
            <i class="ti-anchor" /> ti-anchor
          </div>
          <div class="m-icon">
            <i class="ti-alert" /> ti-alert
          </div>
          <div class="m-icon">
            <i class="ti-alarm-clock" /> ti-alarm-clock
          </div>
          <div class="m-icon">
            <i class="ti-agenda" /> ti-agenda
          </div>
          <div class="m-icon">
            <i class="ti-write" /> ti-write
          </div>
          <div class="m-icon">
            <i class="ti-wallet" /> ti-wallet
          </div>
          <div class="m-icon">
            <i class="ti-video-clapper" /> ti-video-clapper
          </div>
          <div class="m-icon">
            <i class="ti-video-camera" /> ti-video-camera
          </div>
          <div class="m-icon">
            <i class="ti-vector" /> ti-vector
          </div>
          <div class="m-icon">
            <i class="ti-support" /> ti-support
          </div>
          <div class="m-icon">
            <i class="ti-stamp" /> ti-stamp
          </div>
          <div class="m-icon">
            <i class="ti-slice" /> ti-slice
          </div>
          <div class="m-icon">
            <i class="ti-shortcode" /> ti-shortcode
          </div>
          <div class="m-icon">
            <i class="ti-receipt" /> ti-receipt
          </div>
          <div class="m-icon">
            <i class="ti-pin2" /> ti-pin2
          </div>
          <div class="m-icon">
            <i class="ti-pin-alt" /> ti-pin-alt
          </div>
          <div class="m-icon">
            <i class="ti-pencil-alt2" /> ti-pencil-alt2
          </div>
          <div class="m-icon">
            <i class="ti-eraser" /> ti-eraser
          </div>
          <div class="m-icon">
            <i class="ti-more" /> ti-more
          </div>
          <div class="m-icon">
            <i class="ti-more-alt" /> ti-more-alt
          </div>
          <div class="m-icon">
            <i class="ti-microphone-alt" /> ti-microphone-alt
          </div>
          <div class="m-icon">
            <i class="ti-magnet" /> ti-magnet
          </div>
          <div class="m-icon">
            <i class="ti-line-double" /> ti-line-double
          </div>
          <div class="m-icon">
            <i class="ti-line-dotted" /> ti-line-dotted
          </div>
          <div class="m-icon">
            <i class="ti-line-dashed" /> ti-line-dashed
          </div>
          <div class="m-icon">
            <i class="ti-ink-pen" /> ti-ink-pen
          </div>
          <div class="m-icon">
            <i class="ti-info-alt" /> ti-info-alt
          </div>
          <div class="m-icon">
            <i class="ti-help-alt" /> ti-help-alt
          </div>
          <div class="m-icon">
            <i class="ti-headphone-alt" /> ti-headphone-alt
          </div>
          <div class="m-icon">
            <i class="ti-gallery" /> ti-gallery
          </div>
          <div class="m-icon">
            <i class="ti-face-smile" /> ti-face-smile
          </div>
          <div class="m-icon">
            <i class="ti-face-sad" /> ti-face-sad
          </div>
          <div class="m-icon">
            <i class="ti-credit-card" /> ti-credit-card
          </div>
          <div class="m-icon">
            <i class="ti-comments-smiley" /> ti-comments-smiley
          </div>
          <div class="m-icon">
            <i class="ti-time" /> ti-time
          </div>
          <div class="m-icon">
            <i class="ti-share" /> ti-share
          </div>
          <div class="m-icon">
            <i class="ti-share-alt" /> ti-share-alt
          </div>
          <div class="m-icon">
            <i class="ti-rocket" /> ti-rocket
          </div>
          <div class="m-icon">
            <i class="ti-new-window" /> ti-new-window
          </div>
          <div class="m-icon">
            <i class="ti-rss" /> ti-rss
          </div>
          <div class="m-icon">
            <i class="ti-rss-alt" /> ti-rss-alt
          </div>
        </div>
      </vs-card>
      <vs-card>
        <h3 class="card-title d-flex">
          Control Icons
        </h3>
        <div class="icon-list-demo row">
          <div class="m-icon">
            <i class="ti-control-stop" /> ti-control-stop
          </div>
          <div class="m-icon">
            <i class="ti-control-shuffle" /> ti-control-shuffle
          </div>
          <div class="m-icon">
            <i class="ti-control-play" /> ti-control-play
          </div>
          <div class="m-icon">
            <i class="ti-control-pause" /> ti-control-pause
          </div>
          <div class="m-icon">
            <i class="ti-control-forward" /> ti-control-forward
          </div>
          <div class="m-icon">
            <i class="ti-control-backward" /> ti-control-backward
          </div>
          <div class="m-icon">
            <i class="ti-volume" /> ti-volume
          </div>
          <div class="m-icon">
            <i class="ti-control-skip-forward" /> ti-control-skip-forward
          </div>
          <div class="m-icon">
            <i class="ti-control-skip-backward" /> ti-control-skip-backward
          </div>
          <div class="m-icon">
            <i class="ti-control-record" /> ti-control-record
          </div>
          <div class="m-icon">
            <i class="ti-control-eject" /> ti-control-eject
          </div>
        </div>
      </vs-card>
      <vs-card>
        <h3 class="card-title d-flex">
          Text Editor Icons
        </h3>
        <div class="icon-list-demo row">
          <div class="m-icon">
            <i class="ti-paragraph" /> ti-paragraph
          </div>
          <div class="m-icon">
            <i class="ti-uppercase" /> ti-uppercase
          </div>
          <div class="m-icon">
            <i class="ti-underline" /> ti-underline
          </div>
          <div class="m-icon">
            <i class="ti-text" /> ti-text
          </div>
          <div class="m-icon">
            <i class="ti-Italic" /> ti-Italic
          </div>
          <div class="m-icon">
            <i class="ti-smallcap" /> ti-smallcap
          </div>
          <div class="m-icon">
            <i class="ti-list" /> ti-list
          </div>
          <div class="m-icon">
            <i class="ti-list-ol" /> ti-list-ol
          </div>
          <div class="m-icon">
            <i class="ti-align-right" /> ti-align-right
          </div>
          <div class="m-icon">
            <i class="ti-align-left" /> ti-align-left
          </div>
          <div class="m-icon">
            <i class="ti-align-justify" /> ti-align-justify
          </div>
          <div class="m-icon">
            <i class="ti-align-center" /> ti-align-center
          </div>
          <div class="m-icon">
            <i class="ti-quote-right" /> ti-quote-right
          </div>
          <div class="m-icon">
            <i class="ti-quote-left" /> ti-quote-left
          </div>
        </div>
      </vs-card>
      <vs-card>
        <h3 class="card-title d-flex">
          Layout Icons
        </h3>
        <div class="icon-list-demo row">
          <div class="m-icon">
            <i class="ti-layout-width-full" /> ti-layout-width-full
          </div>
          <div class="m-icon">
            <i class="ti-layout-width-default" /> ti-layout-width-default
          </div>
          <div class="m-icon">
            <i class="ti-layout-width-default-alt" /> ti-layout-width-default-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-tab" /> ti-layout-tab
          </div>
          <div class="m-icon">
            <i class="ti-layout-tab-window" /> ti-layout-tab-window
          </div>
          <div class="m-icon">
            <i class="ti-layout-tab-v" /> ti-layout-tab-v
          </div>
          <div class="m-icon">
            <i class="ti-layout-tab-min" /> ti-layout-tab-min
          </div>
          <div class="m-icon">
            <i class="ti-layout-slider" /> ti-layout-slider
          </div>
          <div class="m-icon">
            <i class="ti-layout-slider-alt" /> ti-layout-slider-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-sidebar-right" /> ti-layout-sidebar-right
          </div>
          <div class="m-icon">
            <i class="ti-layout-sidebar-none" /> ti-layout-sidebar-none
          </div>
          <div class="m-icon">
            <i class="ti-layout-sidebar-left" /> ti-layout-sidebar-left
          </div>
          <div class="m-icon">
            <i class="ti-layout-placeholder" /> ti-layout-placeholder
          </div>
          <div class="m-icon">
            <i class="ti-layout-menu" /> ti-layout-menu
          </div>
          <div class="m-icon">
            <i class="ti-layout-menu-v" /> ti-layout-menu-v
          </div>
          <div class="m-icon">
            <i class="ti-layout-menu-separated" /> ti-layout-menu-separated
          </div>
          <div class="m-icon">
            <i class="ti-layout-menu-full" /> ti-layout-menu-full
          </div>
          <div class="m-icon">
            <i class="ti-layout-media-right" /> ti-layout-media-right
          </div>
          <div class="m-icon">
            <i class="ti-layout-media-right-alt" /> ti-layout-media-right-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-media-overlay" /> ti-layout-media-overlay
          </div>
          <div class="m-icon">
            <i class="ti-layout-media-overlay-alt" /> ti-layout-media-overlay-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-media-overlay-alt-2" /> ti-layout-media-overlay-alt-2
          </div>
          <div class="m-icon">
            <i class="ti-layout-media-left" /> ti-layout-media-left
          </div>
          <div class="m-icon">
            <i class="ti-layout-media-left-alt" /> ti-layout-media-left-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-media-center" /> ti-layout-media-center
          </div>
          <div class="m-icon">
            <i class="ti-layout-media-center-alt" /> ti-layout-media-center-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-list-thumb" /> ti-layout-list-thumb
          </div>
          <div class="m-icon">
            <i class="ti-layout-list-thumb-alt" /> ti-layout-list-thumb-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-list-post" /> ti-layout-list-post
          </div>
          <div class="m-icon">
            <i class="ti-layout-list-large-image" /> ti-layout-list-large-image
          </div>
          <div class="m-icon">
            <i class="ti-layout-line-solid" /> ti-layout-line-solid
          </div>
          <div class="m-icon">
            <i class="ti-layout-grid4" /> ti-layout-grid4
          </div>
          <div class="m-icon">
            <i class="ti-layout-grid3" /> ti-layout-grid3
          </div>
          <div class="m-icon">
            <i class="ti-layout-grid2" /> ti-layout-grid2
          </div>
          <div class="m-icon">
            <i class="ti-layout-grid2-thumb" /> ti-layout-grid2-thumb
          </div>
          <div class="m-icon">
            <i class="ti-layout-cta-right" /> ti-layout-cta-right
          </div>
          <div class="m-icon">
            <i class="ti-layout-cta-left" /> ti-layout-cta-left
          </div>
          <div class="m-icon">
            <i class="ti-layout-cta-center" /> ti-layout-cta-center
          </div>
          <div class="m-icon">
            <i class="ti-layout-cta-btn-right" /> ti-layout-cta-btn-right
          </div>
          <div class="m-icon">
            <i class="ti-layout-cta-btn-left" /> ti-layout-cta-btn-left
          </div>
          <div class="m-icon">
            <i class="ti-layout-column4" /> ti-layout-column4
          </div>
          <div class="m-icon">
            <i class="ti-layout-column3" /> ti-layout-column3
          </div>
          <div class="m-icon">
            <i class="ti-layout-column2" /> ti-layout-column2
          </div>
          <div class="m-icon">
            <i class="ti-layout-accordion-separated" /> ti-layout-accordion-separated
          </div>
          <div class="m-icon">
            <i class="ti-layout-accordion-merged" /> ti-layout-accordion-merged
          </div>
          <div class="m-icon">
            <i class="ti-layout-accordion-list" /> ti-layout-accordion-list
          </div>
          <div class="m-icon">
            <i class="ti-widgetized" /> ti-widgetized
          </div>
          <div class="m-icon">
            <i class="ti-widget" /> ti-widget
          </div>
          <div class="m-icon">
            <i class="ti-widget-alt" /> ti-widget-alt
          </div>
          <div class="m-icon">
            <i class="ti-view-list" /> ti-view-list
          </div>
          <div class="m-icon">
            <i class="ti-view-list-alt" /> ti-view-list-alt
          </div>
          <div class="m-icon">
            <i class="ti-view-grid" /> ti-view-grid
          </div>
          <div class="m-icon">
            <i class="ti-upload" /> ti-upload
          </div>
          <div class="m-icon">
            <i class="ti-download" /> ti-download
          </div>
          <div class="m-icon">
            <i class="ti-loop" /> ti-loop
          </div>
          <div class="m-icon">
            <i class="ti-layout-sidebar-2" /> ti-layout-sidebar-2
          </div>
          <div class="m-icon">
            <i class="ti-layout-grid4-alt" /> ti-layout-grid4-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-grid3-alt" /> ti-layout-grid3-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-grid2-alt" /> ti-layout-grid2-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-column4-alt" /> ti-layout-column4-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-column3-alt" /> ti-layout-column3-alt
          </div>
          <div class="m-icon">
            <i class="ti-layout-column2-alt" /> ti-layout-column2-alt
          </div>
        </div>
      </vs-card>
      <vs-card>
        <h3 class="card-title d-flex">
          Brand Icons
        </h3>
        <div class="icon-list-demo row">
          <div class="m-icon">
            <i class="ti-flickr" /> ti-flickr
          </div>
          <div class="m-icon">
            <i class="ti-flickr-alt" /> ti-flickr-alt
          </div>
          <div class="m-icon">
            <i class="ti-instagram" /> ti-instagram
          </div>
          <div class="m-icon">
            <i class="ti-google" /> ti-google
          </div>
          <div class="m-icon">
            <i class="ti-github" /> ti-github
          </div>
          <div class="m-icon">
            <i class="ti-facebook" /> ti-facebook
          </div>
          <div class="m-icon">
            <i class="ti-dropbox" /> ti-dropbox
          </div>
          <div class="m-icon">
            <i class="ti-dropbox-alt" /> ti-dropbox-alt
          </div>
          <div class="m-icon">
            <i class="ti-dribbble" /> ti-dribbble
          </div>
          <div class="m-icon">
            <i class="ti-apple" /> ti-apple
          </div>
          <div class="m-icon">
            <i class="ti-android" /> ti-android
          </div>
          <div class="m-icon">
            <i class="ti-yahoo" /> ti-yahoo
          </div>
          <div class="m-icon">
            <i class="ti-trello" /> ti-trello
          </div>
          <div class="m-icon">
            <i class="ti-stack-overflow" /> ti-stack-overflow
          </div>
          <div class="m-icon">
            <i class="ti-soundcloud" /> ti-soundcloud
          </div>
          <div class="m-icon">
            <i class="ti-sharethis" /> ti-sharethis
          </div>
          <div class="m-icon">
            <i class="ti-sharethis-alt" /> ti-sharethis-alt
          </div>
          <div class="m-icon">
            <i class="ti-reddit" /> ti-reddit
          </div>
          <div class="m-icon">
            <i class="ti-microsoft" /> ti-microsoft
          </div>
          <div class="m-icon">
            <i class="ti-microsoft-alt" /> ti-microsoft-alt
          </div>
          <div class="m-icon">
            <i class="ti-linux" /> ti-linux
          </div>
          <div class="m-icon">
            <i class="ti-jsfiddle" /> ti-jsfiddle
          </div>
          <div class="m-icon">
            <i class="ti-joomla" /> ti-joomla
          </div>
          <div class="m-icon">
            <i class="ti-html5" /> ti-html5
          </div>
          <div class="m-icon">
            <i class="ti-css3" /> ti-css3
          </div>
          <div class="m-icon">
            <i class="ti-drupal" /> ti-drupal
          </div>
          <div class="m-icon">
            <i class="ti-wordpress" /> ti-wordpress
          </div>
          <div class="m-icon">
            <i class="ti-tumblr" /> ti-tumblr
          </div>
          <div class="m-icon">
            <i class="ti-tumblr-alt" /> ti-tumblr-alt
          </div>
          <div class="m-icon">
            <i class="ti-skype" /> ti-skype
          </div>
          <div class="m-icon">
            <i class="ti-youtube" /> ti-youtube
          </div>
          <div class="m-icon">
            <i class="ti-vimeo" /> ti-vimeo
          </div>
          <div class="m-icon">
            <i class="ti-vimeo-alt" /> ti-vimeo-alt
          </div>
          <div class="m-icon">
            <i class="ti-twitter" /> ti-twitter
          </div>
          <div class="m-icon">
            <i class="ti-twitter-alt" /> ti-twitter-alt
          </div>
          <div class="m-icon">
            <i class="ti-linkedin" /> ti-linkedin
          </div>
          <div class="m-icon">
            <i class="ti-pinterest" /> ti-pinterest
          </div>
          <div class="m-icon">
            <i class="ti-pinterest-alt" /> ti-pinterest-alt
          </div>
          <div class="m-icon">
            <i class="ti-themify-logo" /> ti-themify-logo
          </div>
          <div class="m-icon">
            <i class="ti-themify-favicon" /> ti-themify-favicon
          </div>
          <div class="m-icon">
            <i class="ti-themify-favicon-alt" /> ti-themify-favicon-alt
          </div>
        </div>
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  name: 'ThemifyIcons',
  data: () => ({
    title: 'ThemifyIcons'
  })
};
</script>


